@use 'sass:math';

/* ========================================================================================= */
/* SETTINGS - COMMON */
/* ========================================================================================= */

$main-color: #233b74;
$main-color-lighter: #3458ad;

// COLORS
$co-black: #000;
$co-white: #fff;

$co-greylighter: #ededed;

$co-greyTextDark: #231f20;
$co-greyText: #8b9196;
$co-greyBg: #f5f6f9;
$co-greyBorder: #e1e1e1;

$co-inputBorder: #cacbcb;

$co-cadetblue: $main-color;
$co-cadetblueDark: $main-color-lighter;
$co-cadetblueLight: #a6dde3;

$co-purple: $main-color;
$co-purpleDark: $main-color-lighter;
$co-purpleLight: #c2a1dd;

$co-greyBgDark: #e8e9ec;
$co-greyDark: #64696d;

$co-red: #c31622;

$co-errorBg: #d7655d;
$co-successBg: #5e9160;
$co-neutralBg: #8b9196;

$co-greenStatus: #b0d487;
$co-darkgreenStatus: #5e9160;
$co-orangeStatus: #eca153;
$co-blueStatus: $main-color;
$co-purpleStatus: #c79ad4;
$co-redStatus: #de5e67;
$co-greyStatus: #b8c0c7;
$co-celesteStatus: #07b2d6;
$co-navyStatus: #77a6d2;
$co-yellowStatus: #ecdd68;

// COLOR STATUS

$co-clientCandidato: $co-navyStatus;
$co-clientPresentacion: $co-orangeStatus;
$co-clientTomaDatos: $co-blueStatus;
$co-clientPrimeraOferta: $co-purpleStatus;
$co-clientOfertaNueva: $co-celesteStatus;
$co-clientOfertaAceptada: $co-redStatus;
$co-clientPosibleComprador: $co-yellowStatus;
$co-clientGuardado: $co-greyStatus;
$co-clientFinalizado: $co-greenStatus;

$co-projectEnCreacion: $co-greenStatus;
$co-projectActivo: $co-purpleStatus;
$co-projectAceptado: $co-blueStatus;
$co-projectRechazado: $co-orangeStatus;
$co-projectSolicitado: $co-greenStatus;
$co-projectPolizaRechazada: $co-redStatus;
$co-projectPolizaAceptada: $co-navyStatus;
$co-projectFirmado: $co-darkgreenStatus;
$co-projectFinalizado: $co-celesteStatus;
$co-projectCancelado: $co-yellowStatus;

// FONTS

// HTML font-size
$fs-root: 16px;

// Font Family
$ff-primary: 'Futura Std', Helvetica, Arial, sans-serif;

/// Convert px to rem based on the root font-size
@function rem($size) {
  @return math.div($size, $fs-root) + 0rem;
}

// Font Size
$fs-verysmall: rem(12px);
$fs-small: rem(14px);
$fs-medium: rem(16px);
$fs-big: rem(20px);
$fs-verybig: rem(36px);

// Font Weight
$fw-light: 300;
$fw-book: 400;
$fw-medium: 500;
$fw-bold: 700;
$fw-black: 900;

// z-index

$zi-modal: 100;
$zi-menu: 10;
$zi-popup: 1;
$zi-negative: -1;

// GRID BREAKPOINTS
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 1025px,
  lg: 1366px,
  xl: 1500px,
) !default;

// HTML elements size

$ele-header: (
  xs: 68px,
  md: 89px,
);
$ele-snackbar: 52px;
$ele-calendar: 256px;
$ele-mainMenuWidth: 72px;
$ele-mainMenuWidthOpen: (
  xs: 169px,
  lg: 200px,
);
$ele-menuRight: (
  xs: 160px,
  lg: 200px,
);

$ele-labelWidth: (
  xxs: 82px,
  xs: 160px,
  md: 200px,
  lg: 240px,
);

$ele-formWidth: (
  md: 540px,
  lg: 640px,
);

$ele-cellWidth: (
  ellips: 146px,
  phone: 160px,
  date: 134px,
  actions: 63px,
);
