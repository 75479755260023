@use "../../../styles/modules.scss" as *;

.th {
  @include font($fs: $fs-small, $fw: $fw-book, $lh: rem(17px), $co: $co-black);
  padding: 17px 10px 16px 17px;
  text-align: left;
  white-space: nowrap;
  background-color: $co-greyBgDark;

  & + & {
    padding-left: 10px;
  }

  &.hasEllipsis {
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: ele-cellWidth(ellipsis);
  }
}

.isDark {
  color: $co-white;
  background-color: $co-greyText;
}

.isGreen {
  color: $co-white;
  background-color: $co-cadetblue;
}

.isEqualWidth2 {
  width: 22%;
}

.isEqualWidth3 {
  width: 18%;
}

.isEqualWidth4 {
  width: 14%;
}

.isSubtitle {
  background-color: $co-greyBgDark;
  padding: 18px 20px 16px;
  margin: 10px 0;
  @include font($fs: $fs-medium, $fw: $fw-bold, $lh: rem(19px), $co: $co-black);
}

.isBigger {
  font-size: $fs-medium;
}

@include media-breakpoint-up(lg) {
  .th {
    @include font($fs: $fs-medium, $lh: rem(19px));
    padding: 17px 14px 16px 18px;

    & + & {
      padding-left: 14px;
    }

    &.hasEllipsis {
      max-width: initial;
    }
  }
}
