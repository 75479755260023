@use '../../../../../styles/modules.scss' as *;

.row {
  width: 100%;

  [class*='title'] + &,
  & + & {
    margin-top: 8px;
  }
  &.isNoMargin {
    margin: 0;
  }
  &.isInline {
    display: flex;
    justify-content: flex-end;
    padding: 20px;

    & > label {
      width: 177px;
      line-height: 20px;
    }
  }
}

.label {
  @include font($fs: $fs-medium, $fw: $fw-book, $co: $co-black, $lh: rem(19px));

  &.isSmall {
    @include font($fs: $fs-medium, $lh: rem(19px));
  }
}

.inputText {
  @include font($fs: rem(18px), $fw: $fw-book, $lh: 22px, $co: $co-black);
  border: 0;
  border-bottom: 1px solid $co-inputBorder;
  padding: 16px 11px;
  width: 100%;

  &.hasError,
  &.hasLoginError {
    color: $co-red;
    border-color: $co-red;
    background: $co-white url('../../../../../assets/icon_alert_red.svg')
      no-repeat right 10px center;

    &.isSmall,
    &.isTiny {
      border-color: $co-red;
    }
  }

  &.isSmall {
    @include font($fs: $fs-medium, $lh: rem(19px));
    border: 1px solid $co-inputBorder;
    border-radius: 3px;
    padding: 7px 8px 6px;
    display: block;
    width: 100%;
    max-width: 100%;
  }

  &.isTiny {
    @include font($fs: $fs-small, $lh: rem(14px));
    padding: 8px 10px 8px 10px;
    width: 150px;
    border: 1px solid $co-inputBorder;
    margin: -10px 0;
    text-align: right;
  }

  &:not(.isSmall) {
    &:disabled {
      background-color: transparent;
      opacity: 0.4;
    }
  }

  &.isReadOnly {
    &:disabled {
      opacity: 1;
    }
  }

  &::-webkit-datetime-edit {
    color: $co-greyText;

    &:focus {
      color: $co-black;
    }
  }

  &.hasValue {
    &::-webkit-datetime-edit {
      color: $co-black;
    }
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.errorText {
  @include font($fs: $fs-medium, $fw: $fw-book, $lh: rem(19px), $co: $co-red);
  margin-bottom: 10px;
  margin-top: 8px;

  .isSmall ~ & {
    @include font($fs: rem(18px), $lh: rem(16px));
  }
}

.hasError {
  color: inherit;
}

.inputContainer {
  position: relative;
  &.hasTooltip {
    display: inline-block;
  }
}

.currency {
  position: absolute;
  right: 14px;
  top: 10px;
}

@include media-breakpoint-up(lg) {
  .label {
    @include font($fs: rem(18px), $lh: rem(21px));

    &.isSmall {
      @include font($fs: rem(18px), $lh: rem(21px));
    }
  }

  .inputText {
    @include font($fs: rem(18px), $lh: rem(21px));

    &.isSmall {
      @include font($fs: rem(18px), $lh: rem(21px));
      padding: 9px 10px 8px;
    }
    &.isTiny {
      @include font($fs: $fs-medium, $lh: rem(19px));
      margin: -17px 0 -7px;
    }
  }

  .errorText {
    @include font($fs: rem(18px), $lh: rem(21px));
    margin-bottom: 12px;
    margin-top: 10px;
  }
}
