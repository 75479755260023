.table {
  width: 100%;
  border-spacing: 0 2px;
  border-collapse: separate;

  &.isBox {
    border-spacing: 0 5px;

    tbody:before {
      display: none;
    }
  }

  &.is4Columns {
    td,
    th {
      width: calc(100% / 3);
    }
  }

  &.is5Columns {
    td,
    th {
      width: calc(100% / 4);
    }
  }

  &.is6Columns {
    td,
    th {
      width: calc(100% / 5);
    }
  }
}
