@import '../../../styles/modules.scss';

.label {
  @include font($fw: $fw-book, $fs: $fs-medium, $co: $co-black, $lh: rem(19px));
}

.row {
  width: 100%;
}

.selectDropDown {
  @include font(
    $fs: $fs-medium,
    $fw: $fw-book,
    $co: $co-greyText,
    $lh: rem(19px)
  );
  background: url('../../../assets/icon_arrow.svg') no-repeat right 22px center;
  border: 1px solid $co-inputBorder;
  border-radius: 3px;
  background-color: $co-white;
  -webkit-appearance: none;
  padding: 13px 20px 12px;
  @-moz-document url-prefix() {
    padding-left: 15px;
  }

  &:hover {
    cursor: pointer;
  }

  &:invalid {
    color: $co-greyText;
  }
  &.isTiny {
    @include font($fs: $fs-small, $lh: rem(14px));
    padding: 8px 10px 8px 10px;
    width: 100px;
    border: 1px solid $co-inputBorder;
    margin: -10px 0;
    text-align: right;
    background-position: right 6px center;
  }
}

.hasValue {
  color: $co-black;
}

.asterisk {
  color: $co-red;
}

.errorText {
  @include font($fs: $fs-medium, $fw: $fw-book, $lh: rem(19px), $co: $co-red);
  margin-bottom: 10px;
  margin-top: 8px;
}

@include media-breakpoint-up(lg) {
  .selectDropDown {
    @include font($fs: rem(18px), $lh: rem(21px));
    &.isTiny {
      @include font($fs: $fs-medium, $lh: rem(19px));
      margin: -17px 0 -7px;
    }
  }

  .label {
    @include font($fs: rem(18px), $lh: rem(21px));
  }

  .errorText {
    @include font($fs: rem(18px), $lh: rem(21px));
  }
}
