@use '../../styles/modules.scss' as *;

.root {
  display: flex;
  height: 100vh;
  background-color: $co-white;

  button {
    margin-top: 62px;
  }
}

.wrapper {
  background-color: white;
  //max-width: calc(100% - 400px);
  width: 100%;
  max-width: calc(336px + 40px);
  margin: 0 auto;
  padding: 124px 20px 0;
}

.hasError {
  color: inherit;
  background-color: yellow;
}

.iconInput {
  position: absolute;
  left: 10px;
  z-index: 1;

  &.isMail {
    bottom: 14px;
  }

  &.isPassword {
    bottom: 10px;
    left: 12px;
  }
}

.inputWrapper {
  position: relative;
  margin-top: 61px;

  & + & {
    margin-top: 14px;
  }

  input {
    width: 100%;
    padding-left: 48px;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $co-inputBorder;
  }

  [class*='errorText'] {
    display: none;
  }

  [class*='inputField_label'] {
    display: block;
    margin-bottom: 6px;
  }
}

.rememberLink {
  @include font($fs: $fs-small, $fw: $fw-book, $lh: rem(17px), $co: $co-black);
  display: block;
  margin-top: 30px;
}

.errorText {
  @include font($fs: $fs-medium, $fw: $fw-book, $lh: rem(19px), $co: $co-red);
  margin-top: 38px;

  & + & {
    margin-top: 10px;
  }
}

@include media-breakpoint-up(md) {
  .wrapper {
    padding-top: 12%;
  }
}

@include media-breakpoint-up(lg) {
  .errorText {
    @include font($fs: rem(18px), $lh: rem(21px));
  }

  .rememberLink {
    @include font($fs: $fs-medium, $lh: rem(18px));
  }
}
