@use "../../../styles/modules.scss" as *;

.td {
  @include font($fs: $fs-small, $fw: $fw-book, $lh: rem(17px), $co: $co-black);
  padding: 12px 10px 11px 17px;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;

  & + & {
    padding-left: 10px;
  }

  &:last-child {
    padding-right: 17px;
  }

  &.hasEllipsis {
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: ele-cellWidth(ellips);
  }

  &.icon {
    width: 50px;
    padding-right: 10px;
  }

  &.isUnread {
    font-weight: 900;
  }
}

.isBigWithBorder {
  @include font($fs: rem(16px), $fw: $fw-book, $lh: rem(19px), $co: $co-black);
  padding: 18px 23px 16px;
  border: 1px solid $co-greyBorder;
  border-right: 0;

  & + & {
    border-left: 0;
  }

  &:last-child {
    border-right: 1px solid $co-greyBorder;
  }
}

.isBold {
  font-weight: $fw-bold;
}

.noWrap {
  max-width: 20rem;
  line-height: 24px;
  white-space: pre-wrap;
}

@include media-breakpoint-up(lg) {
  .td {
    @include font($fs: $fs-medium, $lh: rem(19px));
    padding: 17px 14px 16px 18px;

    & + & {
      padding-left: 14px;
    }

    &.hasEllipsis {
      max-width: initial;
    }
  }

  .noWrap {
    line-height: 26px;
  }
}
