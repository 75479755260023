@use "../../styles/modules.scss" as *;

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: $zi-modal;
}

.wrapper {
  position: fixed;
  background: white;
  border-radius: 4px;
  min-height: 310px;
  width: 570px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 60px 40px 62px;
}

.closeButton {
  border: 0;
  background: 0;
  padding: 12px;
  position: absolute;
  right: 8px;
  top: 8px;

  svg {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}
