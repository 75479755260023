@use "../../../styles/modules.scss" as *;

.tableRow {
  background-color: $co-white;
  transition: background-color 0.2s ease-in-out;

  & + & {
    margin-top: 10px;
    border-spacing: 10px;
  }

  &.isClickable:hover {
    background-color: $co-greyBgDark;

    td {
      cursor: pointer;
    }
  }

  &.isSelected {
    background-color: $co-cadetblueLight;

    &:hover {
      background-color: $co-cadetblue;
    }
  }
}
