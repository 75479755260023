@use "../../../../styles/modules.scss" as *;
.root {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 54px;
  text-align: center;
  background-color: #e1001b;
  z-index: 999;
  padding: 8px;
  color: white;
  font-weight: bold;
  @include media-breakpoint-up(lg) {
    top: 50px;
    top: 77px;
    padding: 12px;
  }
}
