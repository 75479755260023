.tooltip {
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 250px;
  background-color: rgba(250, 53, 37, 0.9);
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  border: 1px solid #fa3525;
  position: absolute;
  z-index: 2;
  right: 157px;
  white-space: initial;
  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -15px;
    margin-left: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent transparent #fa3525;
  }
}
